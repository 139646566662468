<template>
  <div>
    <div class="mb-6 flex items-center container">
      <IconPackage class="mr-2 text-neutral-400" />
      <h3 class="text-sm font-medium">
        {{ $t("pages.operationsResources.allAvailableResources") }}
      </h3>
    </div>
    <div class="grid gap-1 sm:container md:gap-4 lg:grid-cols-2">
      <PackageRow
        v-for="assetPackage in assetPackages"
        :key="assetPackage.uuid"
        :asset-package="assetPackage"
        router-link-name="operations-resources-packages-detail"
      />
    </div>
  </div>
</template>

<script>
import PackageRow from "@/components/brand-marketing/packages/PackageRow";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  components: {
    PackageRow,
  },

  beforeRouteEnter(to, _from, next) {
    store.dispatch("preOpeningResources/fetchAssetPackages").then(() => next());
  },

  computed: {
    ...mapGetters("preOpeningResources", ["assetPackages"]),
  },
};
</script>
